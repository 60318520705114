let resumeData = {
    "imagebaseurl":"https://joshsroufe.com",
    "name": "Josh Sroufe",
    "availability": "available",
    "role":[
      {
        "title":"Entrepreneur",
        "interval": 2000
      },
      {
        "title":"Design Director",
        "interval": 2000
      },
      {
        "title":"Husband & Father",
        "interval": 2000
      },
      {
        "title":"Mixed Martial Artist",
        "interval": 2000
      }
    ],
    "meetings":[
      {
        "title":"Let's talk about your needs",
        "img": "images/meetings/meeting1.jpg",
        "description": "I'm eager to learn more about your work. Book a short discovery call to see if we're a good fit for collaborating on your projects.",  
        "duration": 15,
        "price": 0,
        "url": "https://tidycal.com/joshsroufe/intro"
      },
      {
        "title":"Invest in your success",
        "img": "images/meetings/meeting2.jpg",
        "description": "Gain valuable insights and actionable strategies to solve a specific problem, achieve a goal, or improve a skill. A small investment in this consultation can save you time and resources in the long run.",
        "duration": 30,
        "price": 150,
        "url": "https://tidycal.com/joshsroufe/half-hour-consult"
      },
      {
        "title":"Transform your business",
        "img": "images/meetings/meeting3.jpg",
        "description": "Go beyond quick fixes and build a lasting foundation for success. In this comprehensive 1-hour session, we'll analyze your current position, define your long-term goals, and create a roadmap to achieve them.",
        "duration": 60,
        "price": 250,
        "url": "https://tidycal.com/joshsroufe/hour-consult"
      },
    ],
    "facts":[
      {
        "title":"Brazilian Jiu-Jitsu Blue Belt",
        "description": "A martial art that focuses on grappling and ground fighting to subdue an opponent",
        "url": "https://en.wikipedia.org/wiki/Brazilian_jiu-jitsu"
      },
      {
        "title":"Muay Thai Practitioner",
        "description": "A full-contact martial art and combat sport that originated in Thailand",
        "url": "https://en.wikipedia.org/wiki/Muay_Thai"
      },
      {
        "title":"Gifted IQ (136)",
        "description": "Can achieve success in almost any field",
        "url": "files/josh_sroufe_iq.pdf"
      },
      {
        "title":"High Emotional Intelligence",
        "description": "Trustworthy, caring, good listener, and good leader",
        "url": "files/josh_sroufe_eq.pdf"
      },
      {
        "title":"Myers-Briggs INTJ-T",
        "description": "The Turbulent Architect: Imaginative, decisive, ambitious, private, curious, and focused",
        "url": "files/josh_sroufe_myers-briggs.pdf"
      },
      {
        "title":"Enneagram: 1 wing 9",
        "description": "The Rational Idealist: Principled, purposeful, self-controlled, and perfectionistic",
        "url": "files/josh_sroufe_enneagram.pdf"
      }
    ],
    "linkedinId":"joshsroufe",
    "skypeid": "joshsroufe",
    "roleDescription": "with a passion for transforming complex challenges into elegant solutions",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://linkedin.com/in/joshsroufe",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/joshsroufe",
          "className":"fa fa-github"
        },
        //{
        //  "name":"medium",
        //  "url":"https://medium.com/@joshsroufe",
        //  "className":"fa fa-medium small"
        //},
        {
          "name":"instagram",
          "url":"https://www.instagram.com/joshuasroufe/",
          "className":"fa fa-instagram"
        }
      ],
    "aboutme":"I have had the privilege of contributing to significant projects for esteemed companies such as Apple, Google, and Amazon.",
    "address":"California",
    "website":"https://joshsroufe.com",
    "education":[
      {
        "UniversityName":"Academy of Art University",
        "specialization":"Web Design & New Media",
        "start":"2012",
        "end":"2015",
        "Achievements":""
      },
      {
        "UniversityName":"California State University, Stanislaus",
        "specialization":"Communications Studies",
        "start":"2004",
        "end":"2006",
        "Achievements":""
      },
      {
        "UniversityName":"California State University, Chico",
        "specialization":"Media Arts, Design & Technology",
        "start":"2003",
        "end":"2004",
        "Achievements":""
      },
      {
        "UniversityName":"Modesto High School",
        "specialization":"Diploma",
        "start":"1999",
        "end":"2003",
        "Achievements":""
      }
    ],
    "projects":[
      {
        "name":"Arte Suave Elite Network",
        "title":"Co-founder & CPO",
        "start":"2022",
        "end":"Present",
        "url":""
      },
      {
        "name":"Aegis Platform Inc.",
        "title":"Co-founder & CEO",
        "start":"2017",
        "end":"2020",
        "url":""
      },
      {
        "name":"Cribbble",
        "title":"Owner",
        "start":"2015",
        "end":"2018",
        "url":""
      }
    ],
    "work":[
      {
        "CompanyName":"Pulse Labs",
        "logo":"images/logos/pl-logo.svg",
        "specialization":"Director, Design",
        "start":"Oct 2020",
        "end":"Present",
        "location":"Sacramento, CA",
        "Achievements":"Currently leading product direction and design, collaborating effectively with cross-functional teams (engineering, product management, marketing) to drive innovation in research methodologies and gather valuable data at scale in previously inaccessible environments. Cultivating a positive and inspiring work environment for the design team, fostering creativity and collaboration through clear communication and effective design direction."
      },
      {
        "CompanyName":"HouseCanary",
        "logo":"images/logos/hc-logo.svg",
        "specialization":"Senior Product Designer",
        "start":"Aug 2018",
        "end":"Apr 2020",
        "location":"San Francisco, CA",
        "Achievements":"Led the interface design process from ideation to prototyping, employing user research to create intuitive and effective product solutions that met both user needs and business goals. Developed a wide range of design deliverables, including visualizations, storyboards, task flows, wireframes, high-fidelity mockups, and interactive prototypes to effectively communicate design solutions and gather feedback."
      },
      {
        "CompanyName":"Clear Capital",
        "logo":"images/logos/cc-logo.svg",
        "specialization":"Lead UX/UI Designer",
        "start":"May 2015",
        "end":"Aug 2018",
        "location":"Roseville, CA",
        "Achievements":"Led and mentored the design team, providing clear direction and feedback to ensure high-quality deliverables and efficient workflows. Translated complex requirements into intuitive and engaging user interfaces, balancing aesthetics with functionality to create exceptional user experiences. Possessed a keen eye for design, ensuring all visual elements aligned with brand guidelines and industry best practices."
      },
      {
        "CompanyName":"Apple Inc.",
        "logo":"images/logos/apple-logo.svg",
        "specialization":"Visual Designer & Developer",
        "start":"Jun 2007",
        "end":"May 2015",
        "location":"Elk Grove, CA",
        "Achievements":"Spearheaded the MyPath pilot program, a career development initiative for AppleCare employees encompassing career-path planning, goal setting, coaching, feedback, and developmental improvements. Designed and built a reporting tool for Apple headquarters executives to analyze data collected by Forecast Analysts, streamlining decision-making processes. Served as the go-to creative consultant for cross-functional teams, providing design expertise for a variety of screen and print projects. Created IDEA, a web-based platform empowering AppleCare employees to submit ideas for company-wide improvements, fostering innovation and employee engagement."
      }
    ],
    "skillsDescription":"Adept at people management and fostering collaborative environments, with proven ability to provide impactful design direction and demonstrate strong business acumen. Proficient in mobile-first product, marketing, brand, and industrial design principles, with hands-on experience in full-stack and app development. Possess strong critical thinking, problem-solving, and interpersonal skills, complemented by a self-motivated, dependable, and flexible work ethic.",
    "skills":[
      {
        "skillname":"Figma",
        "level":"ninety"
      },
      {
        "skillname":"Adobe CC",
        "level":"eighty-five"
      },
      {
        "skillname":"HTML5",
        "level":"ninety"
      },
      {
        "skillname":"CSS",
        "level":"ninety-five"
      },
      {
        "skillname":"Reactjs",
        "level":"fifty-five"
      },
      {
        "skillname":"SwiftUI",
        "level":"forty-five"
      },
      {
        "skillname":"3D Modeling",
        "level":"thirty-five"
      }
    ],
    "portfolio":[
      {
        "name":"Aegis Smart Home",
        "description":"Case Study",
        "imgurl":"images/portfolio/covers/aegis-study.webp",
        "updated": "Apr 2020",
        "accentColor": "#DC574F",
        "sections":[
          {
            "name":"",
            "direction": "vertical",
            "columns":[
                {
                    "type": "overview",
                    "direction": "vertical",
                    "layout": "full",
                    "logo": "/images/portfolio/studies/aegis/aegis-logo.svg",
                    "company": "Aegis Platform Inc.",
                    "start": "2017",
                    "end": "2019",
                    "text": "Aegis is a SHaaS (Smart Home as a Service) software solution that helps users bring all their favorite smart home devices into one dashboard. Providing an at-a-glance view of the state of all devices, as well as, full control of devices with multi-location and multi-mode support.",
                    "role": [
                        {
                            "label": "Co-Founder"
                        },
                        {
                            "label": "CEO"
                        },
                        {
                            "label": "Product Owner"
                        },
                        {
                            "label": "Lead Designer"
                        },
                        {
                            "label": "Front-End Developer"
                        }
                    ],
                    "team": [
                        {
                            "name": "Eric Martin",
                            "role": [
                                {
                                    "label": "Co-Founder"
                                },
                                {
                                    "label": "COO"
                                },
                                {
                                    "label": "Front-End Developer"
                                }
                            ]
                        },
                        {
                            "name": "Nikko Ambroselli",
                            "role": [
                                {
                                    "label": "Co-Founder"
                                },
                                {
                                    "label": "CTO"
                                },
                                {
                                    "label": "Back-End Developer"
                                }
                            ]
                        }
                    ]
                }
            ]
          },
          {
            "name":"The Challenge",
            "direction": "vertical",
            "columns":[
                {
                    "type": "image-text",
                    "direction": "vertical-reverse",
                    "layout": "full",
                    "text": [
                        {
                            "paragraph": "As an avid user of internet-connected devices, I’ve accumulated several products into my home and life. The more smart home products I brought into my home, the more complicated it got to integrate all of them and seamlessly control them. There just wasn't a single standard for integrating all of my smart home gadgets so that I could control them from one simple interface. I started to get frustrated with having to jump from app to app to manage things that for the most part should just work together. This made me think that I must not be the only one with this problem, so I decided to see what I could do about it."
                        }
                    ],
                    "images": [
                        {
                            "url": "/images/portfolio/studies/aegis/many-apps.webp",
                            "caption": "Just a few of the many apps you may need to manage your smart home."
                        }
                    ]
                }
            ]
          },
          {
            "name":"The Plan",
            "direction": "vertical",
            "columns":[
                {
                    "type": "metric",
                    "direction": "horizontal",
                    "layout": "full",
                    "title": "User Research",
                    "step": 1,
                    "text": [
                        {
                            "paragraph": "I started by scanning discussion boards on social media sites like Reddit and Facebook. I wanted to isolate a user base that was involved enough to research new devices and solutions by talking about their smart home online. Additionally, I needed to determine if they may have already looked for solutions to this problem, and if so, what they were."
                        },
                        {
                            "paragraph": "On average there were about ~60k members in the 20 smart device groups I found matching this use case, for a total of ~1.2M members. Although this is a small segment of the *24.3 million smart homes forecasted in 2018, I set this benchmark as the max potential reach for this product at the time."
                        }
                    ],
                    "images": [
                        
                    ],
                    "links": [
                        {
                            "url": "https://www.spglobal.com/marketintelligence/en/news-insights/blog/smart-homes-in-the-u-s-becoming-more-common-but-still-face-challenges",
                            "label": "*Source: S&P Global"
                        }
                    ],
                    "metrics": [
                        {
                            "count": "24M",
                            "label": "Total Smart Homes"
                        },
                        {
                            "count": "1.2M",
                            "label": "Potential Aegis Users"
                        }
                    ]
                },
                {
                    "type": "metric",
                    "direction": "horizontal",
                    "layout": "full",
                    "title": "Competitive Analysis",
                    "step": 2,
                    "text": [
                        {
                            "paragraph": "As part of my research, I needed to see what other options were out there for users. I found a few companies in this space, all of which had varying success, but they also all seemed to have some significant shortcomings. Either they didn't support the right devices, or they didn't have a user interface, or they required major heavy lifting from the user to custom code a solution. Ultimately, consumers want to choose the devices that best suit them. They want those devices to talk to their other devices, and they don't want to have to build the solution themselves. The main competition consumers were using at the time were Wink, SmartThings, Home Assistant, IFTTT, and Stringify, which on average supported 120 device integrations within their respective systems."
                        }
                    ],
                    "images": [
                        
                    ],
                    "links": [
                        
                    ],
                    "metrics": [
                        {
                            "count": "5",
                            "label": "Competitors"
                        },
                        {
                            "count": "120",
                            "label": "Avg. Integrations"
                        }
                    ]
                },
                {
                    "type": "metric",
                    "direction": "horizontal",
                    "layout": "full",
                    "title": "Market Fit",
                    "step": 3,
                    "text": [
                        {
                            "paragraph": "The smart home market in 2018 was forecasted to be *$28 billion. With 24 million smart homes, that makes the average household spend for that year $1,167. This meant that if we reached the full potential of the 1.2 million user benchmark I set for the product, we could grab a market share within a $1.4 billion market segment. Even if we only obtained 1% of that user base, we could still take a share of a $14 million segment."
                        },
                        {
                            "paragraph": "Armed with this data, I felt confident that Aegis would be able to offer a viable solution that could grab a decent share of the market that would make the product prosperous. More importantly, provide a solution that helps alleviate the tension users face when adopting smart devices."
                        }
                    ],
                    "images": [
                        
                    ],
                    "links": [
                        {
                            "url": "https://technology.ihs.com/607335/global-smart-home-device-revenues-to-reach-28-billion-in-2018",
                            "label": "*Source: IHS Markit"
                        }
                    ],
                    "metrics": [
                        {
                            "count": "$28B",
                            "label": "Total Market 2018"
                        },
                        {
                            "count": "1.2K",
                            "label": "Avg. Houshold Spend"
                        }
                    ]
                }
            ]
          },
          {
            "name":"The Solution",
            "direction": "vertical",
            "columns":[
                {
                    "type": "image-text",
                    "direction": "vertical",
                    "layout": "full",
                    "text": [
                        {
                            "paragraph": "The first thing to accomplish was to ensure that the user understood the state of their home at a glance since this was something that all competitors were failing to accomplish. To start I designed a status panel/ card that would not only show the current alarm mode, but also the high-level state of all the security and climate devices. Additionally, the user could quickly change the alarm mode right from this panel."
                        },
                        {
                            "paragraph": "It was important that this design was responsive and would work on mobile devices as well as on the desktop. On mobile devices, this is the main tab of the app. On tablet or desktop devices, it is the primary card in any view, either the default categories or user-defined groups."
                        }
                    ],
                    "images": [
                        {
                            "url": "/images/portfolio/studies/aegis/modes.webp",
                            "caption": "The status panel provides a summary of all security devices in one quick view, with access to control alarm mode."
                        }
                    ]
                },
                {
                    "type": "image-text",
                    "direction": "vertical",
                    "layout": "full",
                    "text": [
                        {
                            "paragraph": "Now that the high-level state of the security devices has been provided, it was time to show the individual device states as well. To get started I made sure to utilize multi-state buttons that offer accessibility options, such as differentiated icons in addition to color. The mini-state of these devices offered important details about the device's current state. A user could instantly see if the device was on or not, along with any relevant settings like light bulb color, or brightness. Or the current speed setting of a fan. It also showed the user if the device lost connection, or has a low battery. Additionally, for devices with on/off actions, a simple tap/click interaction would toggle the device's powered state."
                        }
                    ],
                    "images": [
                        {
                            "url": "/images/portfolio/studies/aegis/dashboard_states.webp",
                            "caption": "The mini-state buttons for devices, which surface pertinent information without having to dive deeper into that device's info."
                        }
                    ]
                },
                {
                    "type": "image-text",
                    "direction": "vertical",
                    "layout": "full",
                    "text": [
                        {
                            "paragraph": "With the entire state of the home now visible at a glance, it was time to make sure the user could control as much as they needed to as well. Each of the mini-state buttons could be opened up from a more link that appears on mouse hover (long press on touch devices). Within each of the device panels, lives important and relevant details and/ or controls for that specific device."
                        },
                        {
                            "paragraph": "The top part of this panel incorporates actions for closing the panel and turning on/off device notifications. The next section shows the device name and current state icon. From there, any available actions are provided to the user, and depending on which action is taken, contextual options for that specific state will dynamically appear below. Lastly, a list of additional information is presented at the bottom."
                        }
                    ],
                    "images": [
                        {
                            "url": "/images/portfolio/studies/aegis/details.webp",
                            "caption": "Each device has an expanded view with additional details and actions."
                        }
                    ]
                }
            ]
          },
          {
            "name":"The Results",
            "direction": "vertical",
            "columns":[
                {
                    "type": "side-by-side",
                    "direction": "vertical",
                    "layout": "full",
                    "caption": "Category view of Aegis Platform in light and dark mode.",
                    "image1": "/images/portfolio/studies/aegis/light.webp",
                    "image2": "/images/portfolio/studies/aegis/dark.webp"
                },
                {
                    "type": "metric-bar",
                    "direction": "horizontal",
                    "layout": "full",
                    "metrics": [
                        {
                            "count": "2",
                            "label": "Years in Beta"
                        },
                        {
                            "count": "36",
                            "label": "Device Intergrations"
                        },
                        {
                            "count": "1k",
                            "label": "Unique Users"
                        },
                        {
                            "count": "40",
                            "label": "Monthly Active Users"
                        }
                    ]
                },
                {
                    "type": "image-text",
                    "direction": "vertical",
                    "layout": "full",
                    "text": [
                        {
                            "paragraph": "The foundation was set, now it was time to bring it all together. My team and I built out the product and offered it as a beta to anyone who wanted to use it. We gained user feedback and implemented some small and big wins along the way. We overwhelmingly received positive feedback, and users constantly praised our efforts. Throughout the beta (2 years), we supported 36 device integrations, gained ~1,000 unique users, and ~40 monthly active users, 35% of which came back every day."
                        },
                        {
                            "paragraph": "The most requested feature was more device integrations, which just so happened to be the biggest roadblock for us. Because we were a small and new company, trying to get device providers on-boarded was especially difficult. Partially because we had to write each integration manually, and also because most smart home device providers didn't offer third-party access to their APIs at all, or at least not without some kind of partnership agreement. Even the providers that did offer API access were almost always lacking in functionality compared to their native offerings."
                        },
                        {
                            "paragraph": "Shortly, we started to see some big companies like Apple, Google, and Amazon offer home dashboard interfaces. With all the competition in this fragmented market, these bigger companies start to corner the market and buy up smaller companies to gain an advantage and take away access from the others, such as Google buying Nest and then dismantling Works with Nest."
                        },
                        {
                            "paragraph": "This will hopefully lead to a single standard for the connected home, which after all, is the ultimate goal."
                        }
                    ],
                    "images": [
                    
                    ]
                }
            ]
          },
          {
            "name":"The Future",
            "direction": "vertical",
            "columns":[
                {
                    "type": "image-text",
                    "direction": "vertical",
                    "layout": "full",
                    "text": [
                        {
                            "paragraph": "The next steps and future vision for Aegis Platform incorporates multi-location views with high-level info for all devices in each location. Additionally, providing user location support along with local user info like calendar info, music player, time, and weather. With these updates, the connected home would start to actually be smart."
                        }
                    ],
                    "images": [
                        {
                            "url": "/images/portfolio/studies/aegis/future.webp",
                            "caption": "The future vision for Aegis to make the connected home truly smart."
                        }
                    ]
                }
            ]
          }
        ]
      },
      {
        "name":"Pulse Labs",
        "description":"Projects",
        "imgurl":"images/portfolio/covers/pl-projects.webp",
        "updated": "Nov 2021",
        "items":[
          {
            "title":"Pulse Labs Evaluation Platform",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The Pulse Labs evaluation platform is for user researchers, designers, product owners, and any other stakeholder trying to understand the performance of their products. After all the quantitative and qualitative research has been captured. The platform evaluates the results, providing scorecard metrics for all quantifiable data points while surfacing the most interesting qualitative responses. Ultimately, making it easy to identify research findings to share and track. Evaluation even works overtime across studies or through platform comparisons.",
            "imgurl": "/images/portfolio/pl/pl-evaluation-platform.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Pulse Labs Mobile App",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The Pulse Labs mobile app allows study participants to perform a number of actions. In addition to managing their account, they can view their tasks, enrolled studies, and even payment history. The app's primary function is to support automotive research studies by connecting to an external camera to record the participants completing tasks. Additionally, the app supports device screen recording, and on device camera recording to allow for even more types of study data it can capture.",
            "imgurl": "/images/portfolio/pl/pl-mobile.webp",
            "layout": "center",
            "links": [
                {
                  "url": "https://www.figma.com/proto/XpqiWtcIuFFMvz9bqvbaCq/PL-Auto-Participant-Auto-Flow?node-id=2%3A1060&scaling=contain&page-id=0%3A1&starting-point-node-id=2%3A1060&show-proto-sidebar=1",
                  "label": "View Prototype"
                }
            ]
          }
        ]
      },
      {
        "name":"House Canary",
        "description":"Projects",
        "imgurl":"images/portfolio/covers/hc-projects.webp",
        "updated": "Jun 2021",
        "items":[
          {
            "title":"Property Finder",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Investors need to identify solid opportunities fast. The property finder helps surface the best leads to the top and provides intelligent insights into which properties are truly great opportunities for investment. This is accomplished by using market attributes that identify a location, property physical characteristics, and calculating financial goals based on user assumptions.",
            "imgurl": "/images/portfolio/hc/property-finder.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Property Details",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "This is where you can learn a great deal about a property all in one place. Completely interactive with photos, maps, street view, and comparable search. There are even interactive charts and visuals to explore insights like transaction and tax history, value forecast, market analysis, crime reports, school ratings, and more. Whether looking to buy or invest in a new property or review your existing portfolio, the property details page gives you everything you need to make sound investment decisions.",
            "imgurl": "/images/portfolio/hc/pdp.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Data Factory Dashboard",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The data factory team is responsible for ingesting listing data from various MLS sources to one unified data source that is then used by other HouseCanary products and services. This dashboard helps the team monitor, identify, and resolve various types of issues that may occur with that feed.",
            "imgurl": "/images/portfolio/hc/dfd.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"HouseCanary Order Manager System",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The order manager is the place to create single or bulk orders of HouseCanary valuation products. The system allows users to select from a robust list of products, and either enter addresses manually or upload them from a bulk file. Once the order is placed, the software provides in-depth details on the status along with error tracking and tools to correct any of the items in a given order. Once items in the order are fulfilled, all relevant information relating to that property along with the raw data, and the purchased report are provided back to the user.",
            "imgurl": "/images/portfolio/hc/hc-order-manager.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Agile Evaluation Report",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The Agile Evaluation report is one of many that can be ordered from the HouseCanary Order Manager system. It is a home evaluation report comparable to an appraisal but at a fraction of the cost. It entails many intelligent features, including a condition informed valuation model. This report comes with either an external inspection of the property to ensure high accuracy.",
            "imgurl": "/images/portfolio/hc/ae.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"ComeHome Commute Time",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "ComeHome is an externally facing product that provides several details about a property. One of the features it offers is the ability for a user to see the commute time from a given property to one or more different locations. This is helpful for those that want to compare how far a property is from their work or school. In this design, the user can add multiple locations, view the respective route on a map, evaluate the time and distance by commute type (i.e. car, transit, bike, or walk), and simulate rush hour commute expectations.",
            "imgurl": "/images/portfolio/hc/commute.webp",
            "layout": "center",
            "links": [

            ]
          },
          {
            "title":"Lead Feed Buy Box",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "For investors to find investment opportunities, they first need to define their buy box criteria to filter down the noise. The buy box is essentially the parameters by which the property must meet for it to be a viable opportunity. The results of the buy box are what make up the leads. From here there is a bit more diligence to determine the end financials, but this gets them to a shortlist for human review.",
            "imgurl": "/images/portfolio/hc/leadfeed.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Agile Ops QC Platform",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "When data and algorithms are relied on to provide valuations on properties that are part of a $27 trillion market, it’s important to have checks in place that are both automated and human backed. The quality control platform allows valuation specialists to review orders flagged with issues to ensure that the automated and/or inspection backed value is as accurate as possible.",
            "imgurl": "/images/portfolio/hc/qc.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"HouseCanary Enterprise Platform",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "One of my first big projects with HouseCanary was to try to unify all of the enterprise software products. One of the biggest issues was that is wasn't quite clear what app the user was in, and finding where you need to be, or even what is available. My solution was the Platform landing, which shows all the enterprise products and solutions. In addition to this, I created the global nav, which would be used across the products that showed our brand, what application you are in, as well as account settings, notifications, and a quick app switcher.",
            "imgurl": "/images/portfolio/hc/hc-platform.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Mortgage Ecosystem",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Along with Danielle Johnson (Visual Designer), we were tasked with creating a poster to explain the mortgage ecosystem. I had the idea of modeling it after a solar system and provided the overall layout and structure, while Danielle provided visuals to keep the poster on brand while also giving it a retro look. Together we were able to make a pretty solid infographic showcasing the primary, secondary, and aux real-estate markets.",
            "imgurl": "/images/portfolio/hc/mortgage-eco.webp",
            "layout": "cover",
            "links": [

            ]
          },
          {
            "title":"HouseCanary Design Guides",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "To assist with onboarding, I came up with a design system and workflow to follow when working with and creating assets for the consumer product. There ended up being a total of three guides to get started. One for getting your tools and system setup with the correct files, then two more for working with the symbol library, and editing the symbol library.",
            "imgurl": "/images/portfolio/hc/hc-guide.webp",
            "layout": "bottom",
            "links": [

            ]
          }
        ]
      },
      {
        "name":"Clear Capital",
        "description":"Projects",
        "imgurl":"images/portfolio/covers/cc-projects.webp",
        "updated": "May 2019",
        "items":[
          {
            "title":"Clear Collateral Platform",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Clear Capital’s flagship product is a platform dedicated to providing a superior experience to real-estate valuation professionals. This product provides a platform for ordering valuation products, managing a portfolio of properties, creating a personalized workflow for reviewing valuations, and providing superior data on all properties so accurate and timely decisions can be made on collateral.",
            "imgurl": "/images/portfolio/cc/ccp.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Appraiser Scheduler Mobile App",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Scheduling appraisals is a costly and time-consuming process that often brings stress into the home buying process. Usually, all individuals involved are scrambling to ensure that the appraisal is scheduled and completed in time before the close of escrow. This mobile app provides a modern approach to scheduling appraisal inspections so that the process is more efficient, and more importantly brings more transparency to the overall process.",
            "imgurl": "/images/portfolio/cc/scheduler.webp",
            "layout": "center",
            "links": [

            ]
          },
          {
            "title":"ClearProp",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "ClearProp provides 'the big' picture of a property. In addition to showing the in-depth details of the home, it also offers insights into its history. Detailing information on market history and performance over time, and forecasting for the future. The biggest feature is the ability to see the current market properties via a map and choose the right comparable properties that help enforce the value of the subject property.",
            "imgurl": "/images/portfolio/cc/clear-prop.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Chase Hub Kiosk",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Providing a customer with a complete story of a home is no easy task. Many have tried to do this with varying success: Zillow, Trulia, RedFin, etc… The trick is providing views of the home and the surrounding areas in a way that makes the customer feel like they visited the property. That’s what we aimed to do with this product. We provided data about features in the neighborhood and even nearby neighborhoods that are similar. Offering 3D views of the exteriors and a large amount of data about a home’s characteristics, market performance, history, and more.",
            "imgurl": "/images/portfolio/cc/chase-hub.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Modern Appraisal - Appraiser Software",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The modern appraisal software is a workflow management tool that helps appraisers do their job more cost-effectively. It does this by helping appraisers get all the data they need to complete an appraisal, without actually needing to go onsite to inspect the property. This ultimately frees up more time for them to do what they do best, and go through many more appraisals throughout the day. We were able to do this by having assistants go on-site for inspections, and by providing superior data on properties which includes: 360 views of the entire home, MLS, County, and Public Record data, and much more.",
            "imgurl": "/images/portfolio/cc/modern-appraisal.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Clear Capital Style Guide",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "We decided to come up with a new design language at Clear Capital in 2017. It included not only new user interface elements, but also updated colors, typography, and more…",
            "imgurl": "/images/portfolio/cc/ccguide.webp",
            "layout": "cover",
            "links": [

            ]
          },
          {
            "title":"Complexity Score Report",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Understanding why a property may or may not be valued the same as it’s surrounding neighbors is not always black and white. There are features of a property that are more subjective than others and require a bit more finesse to value it accurately. The complexity score sets out to provide a snapshot of how typical the property is to the others around it. This gives the person determining the valuation a good idea on where to start their research.",
            "imgurl": "/images/portfolio/cc/complexity.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"Loan Collateral Advisor",
            "meta": [
                {
                  "label": "Designer"  
                }
            ],
            "summary": "Freddie Mac needed a tool to incorporate into their Loan Advisor Suite that handled their information around collateral. Impressed with Clear Capital’s tech and data offering they decided to let us build this tool to incorporate into their overall suite.",
            "imgurl": "/images/portfolio/cc/lca.webp",
            "layout": "square",
            "links": [

            ]
          }
        ]
      },
      {
        "name":"Apple",
        "description":"Projects",
        "imgurl":"images/portfolio/covers/apple-projects.webp",
        "updated": "Feb 2022",
        "items":[
          {
            "title":"MyPath - Career Development Platform",
            "meta": [
                {
                  "label": "Founder"  
                },
                {
                  "label": "Lead Designer"  
                },
                {
                  "label": "Full-Stack Developer"  
                }
            ],
            "summary": "To address an ongoing dissatisfaction that employees have with their career development and advancement, I came up with an innovative solution that redefines the way rotational opportunities were handled at Apple. I first started by developing a project proposal alongside a Keynote interactive presentation that showcased what this new tool would offer. After shopping the idea around, I was fortunate to have the opportunity to pitch the idea to senior management. The value of the tool was immediately recognized, and I was tasked to develop the tool from the ground up. MyPath allows employees to log in and manage their profiles. They can apply for rotational opportunities, and receive feedback throughout the entire process. All feedback is logged and viewable by the employee and their immediate manager.",
            "imgurl": "/images/portfolio/apple/mypath.webp",
            "layout": "bottom",
            "links": [

            ]
          },
          {
            "title":"HTML Email Templates",
            "meta": [
                {
                  "label": "Designer"  
                },
                {
                  "label": "Developer"  
                }
            ],
            "summary": "The AHA College Program reached out to me for assistance creating a set of email stationeries that could be used within the Mail.app. The copy was provided, along with direction as to what could be user-edited. However, the banners and layout design were created as a result of my direction. I then prepared them all into an installer package so that they could easily be installed in the correct location for Mail to use.",
            "imgurl": "/images/portfolio/apple/emails.webp",
            "layout": "bottom",
            "links": [
                
            ]
          },
          {
            "title":"iApps Logo",
            "meta": [
                {
                  "label": "Designer"  
                }
            ],
            "summary": "The iApps group within AppleCare, which supports the consumer creative applications such as iLife and iWork, put on a contest for a logo design. The goal was to design a graphic that would best represent the group. All submissions were voted on by all the members of iApps, including the management team. As the winner, my logo was printed on t-shirts and drink coasters, as well as used in print and digital communications about the iApps teams company-wide.",
            "imgurl": "/images/portfolio/apple/iapps.webp",
            "layout": "bottom",
            "links": [
                
            ]
          },
          {
            "title":"Firefly Manager Check-In",
            "meta": [
                {
                  "label": "Lead Designer"  
                },
                {
                  "label": "Full-Stack Developer"  
                }
            ],
            "summary": "The firefly web tool was created to track the manager on duty and ensure that there was a central location where AppleCare advisors could go to get support from a manager. In addition to providing a real-time calendar with manager profiles, it also assigns quarterly shifts and provides detailed reporting for senior management.",
            "imgurl": "/images/portfolio/apple/firefly.webp",
            "layout": "square",
            "links": [
                
            ]
          },
          {
            "title":"Analyst Reporting Tool",
            "meta": [
                {
                  "label": "Lead Designer"  
                },
                {
                  "label": "Full-Stack Developer"  
                }
            ],
            "summary": "David Schulz, who at the time was a member of the Forecast Analyst Team, specifically selected me to help him create a web tool that would allow some of the other members of his team to save periodic data records, and for managers to then create cumulative monthly reports to share with the executive team.",
            "imgurl": "/images/portfolio/apple/analyst.webp",
            "layout": "square",
            "links": [
                
            ]
          },
          {
            "title":"Siri Keynote Interaction",
            "meta": [
                {
                  "label": "Designer"  
                }
            ],
            "summary": "Also while working with David, I was often tasked to help with preparing Keynote slide decks for executives. This consisted of anything from design for new or older graphics, to creating complete slide layouts that have full interactive builds and experiences. This example was created for AppleCare Senior VP Tara Bunch, was designed to play along with her in a scripted Siri interaction for an annual communications meeting.",
            "imgurl": "/images/portfolio/apple/phone.gif",
            "layout": "phone",
            "links": [
                
            ]
          },
          {
            "title":"IDEA Submission Tool",
            "meta": [
                {
                  "label": "Founder"  
                },
                {
                  "label": "Lead Designer"  
                },
                {
                  "label": "Front-End Developer"  
                }
            ],
            "summary": "My first project for Apple back in 2008 was the direct result of trying to find a solution to improve the level of innovation within AppleCare. After a few months, a web tool that would allow internal employees to submit suggestions on how to improve the business was born. All submissions were evaluated by a six-person committee, to ultimately determine which were appropriate and feasible.",
            "imgurl": "/images/portfolio/apple/idea.webp",
            "layout": "square",
            "links": [
                
            ]
          }
        ]
      },
      {
        "name":"Miscellaneous",
        "description":"Projects",
        "imgurl":"images/portfolio/covers/misc-projects.webp",
        "updated": "Apr 2022",
        "items":[
          {
            "title":"Arte Suave Elite Website",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "Arte Suave Elite is a Brazillian Jiu Jitsu organization that holds monthly events and honors various non-profits by donating a portion of their proceeds. The team was desperate for a revamp of their website to better showcase their offering and explain who they are. I designed a responsive web experience to support multiple device types and screen sizes. You can view the prototypes from the links below:",
            "imgurl": "/images/portfolio/misc/arte_suave.webp",
            "layout": "center",
            "links": [
                {
                  "url": "https://www.figma.com/proto/2wIMfS78DdzaPetblApy5p/Arte-Suave-Elite?page-id=7%3A4497&node-id=7%3A4498&viewport=493%2C48%2C0.3&scaling=scale-down&starting-point-node-id=7%3A4498",
                  "label": "Mobile Prototype"
                },
                {
                  "url": "https://www.figma.com/proto/2wIMfS78DdzaPetblApy5p/Arte-Suave-Elite?page-id=0%3A1&node-id=120%3A3755&viewport=493%2C48%2C0.18&scaling=scale-down&starting-point-node-id=120%3A3755",
                  "label": "Desktop Prototype"
                }
            ]
          },
          {
            "title":"My Secure Advantage - Action Plan",
            "meta": [
                {
                  "label": "Lead Designer"  
                }
            ],
            "summary": "The team at My Secure Advantage provides financial coaching and solutions. For this particular project, they wanted to offer guided self-help plans that track progress, offer smart suggestions, and are prioritized based on the individual's needs.",
            "imgurl": "/images/portfolio/misc/msa.webp",
            "layout": "center",
            "links": [
                
            ]
          },
          {
            "title":"Exercising Spiritual Authority – Book Cover",
            "meta": [
                {
                  "label": "Graphic Designer"  
                }
            ],
            "summary": "Provided the book cover design for Dr. Dale M. Sides’ newest addition to his Exercising Spiritual Authority series. This cover was designed to showcase strength and movement to emphasis authority and exercise respectively.",
            "imgurl": "/images/portfolio/misc/esa-book.webp",
            "layout": "center",
            "links": [
                
            ]
          },
          {
            "title":"Aegis Platform",
            "meta": [
                {
                  "label": "Co-Founder"  
                },
                {
                  "label": "CEO"  
                },
                {
                  "label": "Product Owner"  
                },
                {
                  "label": "Lead Designer"  
                },
                {
                  "label": "Front-End Developer"  
                }
            ],
            "summary": "As an avid user of internet-connected devices, I’ve accumulated several products into my home and life. Having all these devices required installing a companion mobile app for each one, and most didn’t even have a web interface. There are services like Wink and SmartThings that try to bring all these things to one place, but still, there is not just one place to look to see the current state of all your devices. That’s how Aegis was created! I set out with a couple of partners to create a platform that would bring a consumer-oriented solution to the masses. It offered real-time updates, a status panel to see the current state of the home, full control of devices, personalization, and much more...",
            "imgurl": "/images/portfolio/misc/aegis.webp",
            "layout": "bottom",
            "links": [
                {
                  "url": "/portfolio/case-study/aegis",
                  "label": "View Case Study"
                } 
            ]
          },
          {
            "title":"Cribbble - Invite Platform for Dribbble",
            "meta": [
                {
                  "label": "Product Owner"  
                },
                {
                  "label": "Lead Designer"  
                },
                {
                  "label": "Full-Stack Developer"  
                }
            ],
            "summary": "As an active member of the Dribbble design community, an invite-only show and tell platform for designers, I found that it was somewhat difficult to find new, talented individuals to invite to the site. Dribbble’s built-in solution for this at the time was to list current prospect’s names and basic info. I found this to be somewhat lacking as there wasn't an easy way to determine how talented these individuals were, and if they were a good match for my design style. That is how Cribbble was born. This tool allowed current Dribbble prospects to post one shot that represents their best work. They could also showcase a profile with a link to their full portfolio. In addition to this, any current Dribbble player could post invite opportunities, and browse and invite talent right from within the tool.",
            "imgurl": "/images/portfolio/misc/cribbble.webp",
            "layout": "bottom",
            "links": [

            ]
          }
        ]
      }
    ],
    "testimonials":[
      {
        "description":"Josh is... [an] ultra-solid designer who constantly delivers... quality work... [across various] design challenges. I... hired him to help... design [one of] our flagship products, [and] he ended up leading the design... navigating complex requirements... [He's] also a pleasure to work with on a personal level... If you ever get the chance to hire [him]... consider yourself very lucky.",
        "name":"Jason Kirst",
        "title": "Senior UX Manager",
        "company": "SAP",
        "image": "./images/users/kirst.webp"
      },
      {
        "description":"I had the pleasure to work with Josh while at Housecanary... We brought Josh on for his unique background and vast experience designing for real estate products... [He] always took the challenges head on with a positive approach. He exhibited a clean, polished design aesthetic... backed by thoughtful research and comprehensive flows... [He] continually... sought out ways to positively contribute to projects... [and] would be an impact addition to any design team.",
        "name":"Dennis McNulty",
        "title": "Senior Design Director",
        "company": "HouseCanary",
        "image": "./images/users/mcnulty.webp"
      },
      {
        "description":"Josh is a great designer. He thinks through not just the project on hand but what the feature he's working on can evolve to. Josh takes great initiatives to think about the entire ecosystem and if he sees something that can be improved, he goes ahead and designs it unprompted. Additionally, he's great at getting feedback from engineering early on so that his designs are not over complicated.",
        "name":"Tiffany Yang",
        "title": "Senior Product Manager",
        "company": "Amazon",
        "image": "./images/users/yang.webp"
      },
      {
        "description":"Practical yet beautiful designs, and a desire to approach problems holistically best describe Josh. He clearly cares about the user’s journey and works tirelessly to find the highest level of usability with elegant solutions. I can’t recommend him enough.",
        "name":"Vivian Easley",
        "title": "Web Engineering Manager",
        "company": "HouseCanary",
        "image": "./images/users/easley.webp"
      },
      {
        "description":"I’ve had the pleasure of working with Josh as a Designer and I highly recommend his amazing talent and skills in web design. Josh was our lead designer for a web tool development program for about a year and I was amazed at his talents in design, as well as his creativity. He has an amazing ability for design layout, functionality, as well as the technical skills to build out a very robust program. Josh would be an incredible asset to anyone who wants to design and develop world class websites.",
        "name":"John McGimsey",
        "title": "Project Manager",
        "company": "Apple",
        "image": "./images/users/mcgimsey.webp"
      }
    ]
  };
  
  export default resumeData;