import React, { Component } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';

import Social from './Social';
import Nav from './Nav';
import ThemeSwitch from './ThemeSwitch';

import ReactGA from 'react-ga4';

export default class Header extends Component {

  render() {
    let resumeData = this.props.resumeData; 
      
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/",
      title: "Home",
    });
      
    //let roles = 
    //resumeData.role && resumeData.role.map(item =>{
    //    
    //    return(
    //        "'" + item.title + "', " + Number(item.interval) + "," 
    //        //item.title
    //        //item.interval
    //    );
    //});
    //
    ////let roleStrings = 
    ////roles && roles.forEach((element) => {
    ////    
    ////    return(
    ////        element + ","
    ////    );
    ////});
    //
    //let roleStrings = roles.forEach((element) => {return(element)});
    //  
    ////rolesArray.forEach((element) => element)
    //
    ////roleStrings = roles.toString();
    //
    //console.log(roleStrings);
    
    

    return (
      <React.Fragment>
      
      <header id="home">
        
         <div className="row banner">
            <video id="background-video" autoPlay loop={true} muted={true} playsInline>
                <source src="/images/header-background.mp4" type="video/mp4" />
            </video>
            <div className="banner-text">
                <div className="main">
                    <img className="logo"  src="images/brand/js.svg" alt=""/>
                    <h1 className="responsive-headline">{resumeData.name}</h1>
                    <h3 style={{color:'#fff', fontFamily:'sans-serif '}}>
                        <TypeAnimation
                          //sequence={[roleStrings]}
                          sequence={['Entrepreneur', 2000, 'Design Director', 2000, 'Husband & Father', 2000, 'Mixed Martial Artist', 2000]}
                          style={{ fontSize: '2em', color: '#B7AB98', fontFamily: 'Open Sans'}}
                          repeat={Infinity}
                          cursor={false}
                        />
                    </h3>
                </div>
                <hr />
                <div className="links">
                    <Nav />
                    <ul className="social">
                      {
                        resumeData.socialLinks && resumeData.socialLinks.map(item =>{
                          return(
                                  <li key={item.name}>
                                    <a href={item.url} target="_blank"><i className={item.className}></i></a>
                                  </li>
                                )
                              }
                        )
                      }
                   </ul>
               </div>
            </div>
        </div>
      <ThemeSwitch />
      </header>
      </React.Fragment>
    );
  }
}

//<p>{resumeData.roleDescription}</p>

//resumeData.role

//<nav id="nav-wrap">
//    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
//    <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
//    <ul id="nav" className="nav">
//       <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
//       <li><a className="smoothscroll" href="#about">About</a></li>
//       <li><a className="smoothscroll" href="#resume">Resume</a></li>
//       <li><a className="smoothscroll" href="#portfolio">Works</a></li>
//       <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
//       <li><a className="smoothscroll" href="#contact">Contact</a></li>
//    </ul>
//</nav>
