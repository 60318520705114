import React, { Component, useEffect } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

import Close from './Close';
import Footer from './Footer';
import HeaderSmall from './HeaderSmall';
import Availability from './Availability';

import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'animate.css';

export default class About extends Component {
    render() {
    let resumeData = this.props.resumeData;
        
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/about",
      title: "About",
    });
        
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    
    moment().format("MM/DD/YYYY");
    const now = moment(new Date());
    let today = moment().format("MMM Do");
//
    let born = moment('1984-11-12');
    let birthday = today == "Nov 12th";
    let age = now.diff(born, 'years', true);
      
    let work = moment('2007-06-04'); 
    let experience = now.diff(work, 'years', true);
        
    let projects =  resumeData.portfolio.length;
        
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

    return (
            <div className="container">
              <Close />
              <HeaderSmall resumeData={resumeData} />
              <section id="about"> 
                 <div className="row">

                    <div className="four columns">

                        <img className="profile-pic"  src="images/brand/js_cover.webp" alt="Josh Sroufe" />
                        <img className="signature"  src="images/brand/signature.png" alt="Josh Sroufe Signature" />

                    </div>

                    <div className="eight columns main-col">

                        {/*<h2>My story</h2>*/}
                       <p>My name is {resumeData.name} and I am a {Math.floor(age)}-year-old {resumeData.address} native {resumeData.roleDescription}. With <Link to="/resume" className="highlight">over {Math.floor(experience)} years of experience</Link> in innovative strategy and product design, {resumeData.aboutme} Currently, I lead the design team at {resumeData.work[0].CompanyName}, where we are dedicated to crafting user-centric experiences that truly make a difference.</p>

                       <div className="callouts">
                           <Availability resumeData={resumeData} />
                        </div>
                    </div>
                 </div>
              </section>
              <section id="facts">
                  <div className="row">
                      <div className="twelve columns">
                        <h2>Interesting facts</h2>
                        <ul>
                            {
                        resumeData.facts && resumeData.facts.map((item)=>{
                          return(
                                <li className="six columns" key={item.title}>
                                    <a href={item.url} target="_blank">
                                        <span><span>{item.title}</span> <i className="fa-solid fa-square-up-right"></i></span>
                                        <span>{item.description}</span>
                                    </a>
                                </li>
                                )
                            })
                          }
                        </ul>
                      </div>
                  </div>
              </section>
              <section id="recommendations">
                  <div className="row">
                      <div className="twelve columns">
                    <h2>What others say</h2>
                        <Carousel 
                            responsive={responsive}
                            swipeable={true}
                            showDots={true}
                            arrows={false}
                            infinite={true}
                            deviceType={this.props.deviceType}
                            autoPlay={this.props.deviceType !== "mobile" ? true : false}
                            autoPlaySpeed={10000}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            dotListClass="pagination"
                            minimumTouchDrag="50"
                        >
                        {
                        resumeData.testimonials && resumeData.testimonials.map((item)=>{
                          return(

                                <blockquote key={item.name}>
                                    <p>
                                    {item.description}
                                    </p>
                                    <div className="quote-footer">
                                        <img className="quoter" src={item.image} />
                                        <cite>
                                            <span className="name">{item.name}</span>
                                            <span className="title">{item.title}, {item.company}</span>
                                        </cite>
                                    </div>
                                  </blockquote>
                                )
                            })
                          }
                      </Carousel>
                    </div>
                </div>
              </section>
              <Footer resumeData={resumeData} />
            </div>
    );
  }
}

//<Link to="/resume">
//  <div className="freelancing">
//      <span className="metric">{Math.floor(experience)}+</span> Years of Experience
//  </div>
//</Link>
//<Link to="/portfolio">
//  <div className="freelancing">
//      <span className="metric">{Math.floor(projects)}</span> Portfolio Projects
//  </div>
//</Link>

//<div className="experience">
//   <div className="metric">{Math.floor(experience)}+</div>
//   <div className="label">Years of Experience</div>
//</div>

//<Testimonials resumeData={resumeData} />
//
//<div className="columns contact-details">
//  <h2>Contact Details</h2>
//  <p className="address">
//            <span>{resumeData.name}</span>
//     <br></br>
//               <span>
//     {resumeData.address}
//    </span>
//    <br></br>
//    <span>{resumeData.website}</span>
//  </p>
//</div>

//<Footer resumeData={resumeData} />


//<ul className="slides">
//  {
//    resumeData.testimonials && resumeData.testimonials.map((item)=>{
//      return(
//        <li>
//          <blockquote>
//            <p>
//            {item.description}
//            </p>
//            <div className="quote-footer">
//                <img className="quoter" src={item.image} />
//                <cite>
//                    <span className="name">{item.name}</span>
//                    <span className="title">{item.title}, {item.company}</span>
//                </cite>
//            </div>
//          </blockquote>
//        </li>
//      )
//    })
//  }
//</ul>


//animate__animated animate__slideInUp