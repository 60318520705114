import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const ThemeSwitch = () => {
  const [isDarkMode, setDarkMode] = React.useState(true);

  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  
  const [dark, setDark] = React.useState(prefersDark.matches);
    
  prefersDark.addEventListener("change", e => e.matches && setDark(e.matches) && initDarkMode());
  //prefersDark.addListener(e => e.matches && setDark(e.matches) && initDarkMode() && e.matches ? document.getElementById('theme').className = "App dark" : "App");
    
  console.log(dark);
    
  function initDarkMode() {
     
    if (localStorage.getItem('dark-theme') === "false") {
        setDarkMode(false);
        
        document.getElementById('theme').className = "App";
    }
    else if (localStorage.getItem('dark-theme') === null) {
        
        if (!dark) {
           setDarkMode(false); 
           document.getElementById('theme').className = "App";
        }
        else {
           setDarkMode(true); 
           document.getElementById('theme').className = "App dark";
        }
    }
    
    
  }
    
  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
    
    const theme = document.getElementById('theme');
      
    if(theme != null && theme){
        theme.classList.toggle("dark");
        localStorage.setItem('dark-theme', isDarkMode ? false : true);
    }
    
  };
    
    React.useEffect(() => {      
        initDarkMode();
      }, []);
    
    //React.useLayoutEffect(() => {
    //    /* You end up here only when the user takes action 
    //    to change the theme, hence you can just apply the new theme. */
    //    initDarkMode();
    //}, [dark]);
    
  return (
    <DarkModeSwitch
      checked={isDarkMode}
      onChange={toggleDarkMode}
      size={20}
      className="theme-toggle"
      moonColor="#8A8A8B"
      sunColor="#8A8A8B"
    />
  );
};

export default ThemeSwitch;

//style={{ marginTop: '2rem', marginLeft: '2rem' }}

//style={{color: "#8A8A8B", fill: "#8A8A8B"}}