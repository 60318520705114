import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Close from './Close';
import Footer from './Footer';
import HeaderSmall from './HeaderSmall';
import ContactForm from './ContactForm';

//import { ReactTidyCal } from "react-tidycal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactGA from 'react-ga4';

export default class ContactUs extends Component {
    
  render() {     
  
    let resumeData = this.props.resumeData;
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/contact",
      title: "Contact",
    });
      
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
      
    return (
    <div className="container">
      <Close />
      <HeaderSmall resumeData={resumeData} />
      <section id="contact">
        <div className="row">
            <div className="contact-container">
            <div id="meetings">
                <div className="meeting-container">
                    <div className="meetings-label">
                        <h4>Schedule a Meeting</h4>
                        <i className="fa-regular fa-calendar-plus"></i>
                    </div>
                    <Carousel 
                        responsive={responsive}
                        swipeable={true}
                        showDots={true}
                        infinite={true}
                        arrows={false}
                        deviceType={this.props.deviceType}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={10000}
                        dotListClass="pagination"
                        minimumTouchDrag="50"
                    >
                    {
                    resumeData.meetings && resumeData.meetings.map((item)=>{
                      return(

                            <div key={item.title}>
                            { /*<img src={item.img} className="photo" /> */}
                                <div className="meeting-header">
                                    <h3>{item.title}</h3>
                                    <span className="duration">({item.duration} min)</span>    
                                </div>
                                <p>{item.description}</p>
                                <a href={item.url} target="_blank"><button className="book">Book now {item.price === 0 ? '' : "$"+item.price}</button></a> 
                            </div>
                        )
                    })
                    }
                    </Carousel>
                </div>
            </div>
            <div id="messages">
                {/*<h4>Send a Message</h4>*/}
                <ContactForm />
            </div>
            </div>
        </div>
      </section>
      <Footer resumeData={resumeData} />
    </div>
        );
  }
}

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={true}
//    infinite={true}
//    autoPlay={this.props.deviceType !== "mobile" ? true : false}
//    autoPlaySpeed={10000}
//    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.meetings && resumeData.meetings.map((item)=>{
//  return(
//
//        <div key={item.title}>
//      { /*<img src={item.img} className="photo" /> */}
//            <div className="meeting-header">
//                <h3>{item.title}</h3>
//                <span className="duration">({item.duration} min)</span>    
//            </div>
//            <p>{item.description}</p>
//            <a href={item.url} target="_blank"><button className="book">Book now {item.price === 0 ? '' : "$"+item.price}</button></a> 
//          </div>
//        )
//    })
//  }
//</Carousel>

//<div className="twelve columns">
//    <h2>Ready to connect?</h2>
//    <p>Use the form to send a message or you can request a meeting.</p>
//</div>

//<ReactTidyCal path="joshsroufe/intro" />
//





