import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

import useWeb3Forms from "@web3forms/react";
import 'animate.css';

function ContactForm() {
  const [result, setResult] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);

  const [validForm, setValidForm] = React.useState(false);
  
  const [nameValue, setNameValue] = useState('');
  const [errorName, setErrorName] = useState('');
    
  const [emailValue, setEmailValue] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
    
  const [messageValue, setMessageValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isEmailValid = (email) => {
  
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
    
  const handleNameChange = (e) => {
    const value = e.target.value;
    setNameValue(value);

    // Validate if the input is not empty
    if (!value.trim()) {
      setErrorName('Name is required.');
      setValidForm(false);
    } else {
      setErrorName('');   
      if (nameValue !== '' && emailValue !== '' && messageValue !== '') {
        setValidForm(true);
      }
    }
  };
    
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailValue(value);

    if (!value.trim() || !isEmailValid(value)) {
      setErrorEmail('Invalid email format.');
      setValidForm(false);
    } else {
      setErrorEmail('');
      if (nameValue !== '' && emailValue !== '' && messageValue !== '') {
        setValidForm(true);
      }
    }
  };
    
  //const handleEmailChange = (e) => {
  //  const value = e.target.value;
  //  setEmailValue(value);
//
  //  // Validate if the input is not empty
  //  if (!value.trim()) {
  //    setErrorEmail('Valid email is required.');
  //  } else {
  //    setErrorEmail('');
  //  }
  //};
  //  
  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessageValue(value);

    // Validate if the input is not empty
    if (!value.trim()) {
      setErrorMessage('Message is required.');
      setValidForm(false);
    } else {
      setErrorMessage('');
      if (nameValue !== '' && emailValue !== '' && messageValue !== '') {
        setValidForm(true);
      }
    }
  };
    

  //const handleInputChange = (event) => {
  //  if (setValues({
  //    ...values,
  //    [event.target.name]: event.target.value,
  //  })) {
  //    validate();
  //  }
  //};
    
  //const validate = () => {
  //  let temp= {};
  //  
  //  temp.name = values.name.trim() ? "" : "Name is required";
  //  temp.email = !values.email.trim() ? "" : "Valid email is required";
  //  temp.message = !values.message.trim() ? "" : "Message is required";
//
  //  setErrors({
  //    ...temp
  //  });
//
  //  return Object.values(temp).every(x => x == "");
  //};
    
    //console.log("errors: " + JSON.stringify(errors));
    //console.log("values: " + JSON.stringify(values));
    //console.log("name: " + values.name, "email: " + values.email, "message: " + values.message);
    
  const onSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setIsSuccess(null);
    setResult("Sending....");
    setValidForm(false);
    const formData = new FormData(event.target);

    formData.append("access_key", "90af9092-c751-4767-a25c-8e3fc0cef8b0");

    console.log(event.target);  
      
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setIsSuccess(true);
      setResult("Your message was recieved");
      event.target.reset();
      setNameValue('');
      setEmailValue('');
      setMessageValue('');
    } else {
      setIsSuccess(false);
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input 
            type="text" 
            name="name" 
            placeholder="name" 
            required 
            defaultValue={nameValue}
            onBlur={handleNameChange}
            className={errorName ? "error animate__animated animate__shakeX" : ""}
        />
        {errorName && <p className="helpertext error">{errorName}</p>}
        <input 
            type="email"
            name="email" 
            placeholder="email" 
            required
            defaultValue={emailValue}
            onBlur={handleEmailChange}
            className={errorEmail ? "error animate__animated animate__shakeX" : ""}
        />
        {errorEmail && <p className="helpertext error">{errorEmail}</p>}
        <textarea 
            name="message" 
            placeholder="message" 
            required
            defaultValue={messageValue}
            onBlur={handleMessageChange}
            onChange={handleMessageChange}
            className={errorMessage ? "error animate__animated animate__shakeX" : ""}
        ></textarea>
        {errorMessage && <p className="helpertext error">{errorMessage}</p>}
        <button type="submit" className="submit" disabled={!validForm ?? true}>Send</button>

      </form>
      <span className={"send-result " + (isSuccess ? "message-success" : (isSuccess === null ? "" : "message-warning"))}>{result}</span>

    </div>
  );
}

export default ContactForm;


  //const INITIAL_ARTICLE = {
  //      name: ``,
  //      email: ``,
  //      message: ``,
  //  };
  //  
  //const [article, setArticle] = useState(INTIAL_ARTICLE);